.unselected-rightclick-menu-cell {
  width: 100%;
  height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer; /* Add cursor pointer */
  background: #171723ed;
  user-select: none;
}
.unselected-rightclick-menu-cell:hover {
  background-color: #232335e4;
}

.unselected-menu-left-text {
  font-family: sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: #ffffff; /* White color */
}

.unselected-menu-right-text {
  font-family: sans-serif;
  font-size: 8px;
  font-weight: normal;
  color: #aaaaaa; /* Light grey color */
}

.clear-canvas-cell:hover .unselected-rightclick-menu-cell {
  background-color: #be2323;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
}

.clear-canvas-cell:hover .unselected-menu-right-text {
  color: #fff;
}

.add-all-to-cart-cell:hover .unselected-rightclick-menu-cell {
  background-color: #2e8fff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
}

.add-all-to-cart-cell:hover .unselected-menu-right-text {
  color: #fff;
}
