/*  */
.navbar {
  height: 40px;
  background-color: rgb(255, 115, 0);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.nav-buttons {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav-button {
}

.steam {
  display: flex;
  align-items: right;
  list-style: none;
}

.listItem {
  margin-right: 20px;
  cursor: pointer;
}

.steam-login {
  list-style-type: none;
}

.link-button {
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.Dropdown-items {
  color: black;
}

.center-text {
  text-align: center;
}

.main-box {
  position: relative;
}

.inventory {
  /* display: flex; */
  padding-top: 300px;
  padding-right: 450px;
  padding-left: 450px;
  padding-bottom: 300px;
}

.item_box {
  border-style: solid;
  /* background-color: blue; */
  width: 100px;
  height: 150px;
  padding: 30px;
}

.item_box_sidebar {
  border-style: solid;
  margin: 0 auto;
  width: 150px;
  height: 150px;
}

.item_list_sidebar {
  list-style: none;
}

.item_img {
  width: 80%;
}

.item_img_small {
  width: 40%;
}

.item_title {
  font-size: 12px;
  text-align: center;
}

.item_exterior {
  font-size: 10px;
  text-align: center;
}

.checkbox {
  width: 150px;
  height: 200px;
}

.blue {
  background-color: blue;
}

.red {
  background-color: red;
}

.sidebar {
  border-style: solid;
  position: fixed;
  height: 100%;
  width: 300px;
  top: 70px;
  right: 0;
}

.submit_item_list {
  position: fixed;
  right: 100px;
  bottom: 0;
  width: 100px;
  color: white;
  text-align: center;
}

/* Search Bar */
.search-bar {
  background-color: #171723;
}

.search-bar:hover {
  background-color: #232335; /* Initial background color moved to CSS */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.checkbox-sidebar {
  margin-right: 10px;
  cursor: pointer;
  width: 14px; /* Adjust width as needed */
  height: 14px; /* Adjust height as needed */
  transform: scale(1.5); /* Scale the checkbox for better visibility */
  color: #fff; /* This might not affect the checkbox in some browsers */
  border-radius: 0; /* Removes border radius to make the checkbox square */
  margin-left: 3px;
}

.dropdown-filter-search {
  background-color: #171723;
}

.dropdown-filter-search:hover {
  background-color: #232335; /* Initial background color moved to CSS */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Style the scrollbar */
::-webkit-scrollbar {
  width: 7px; /* Set the width of the scrollbar */
  height: 7px;
}

/* Style the track (background) of the scrollbar */
::-webkit-scrollbar-track {
  background-color: transparent; /* Change the background color of the track */
}

/* Style the thumb (foreground) of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #232335; /* Change the color of the thumb */
  border-radius: 0px; /* Round the corners of the thumb */
  cursor: pointer;

}