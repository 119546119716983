.checkout-page {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Ensure the page covers the entire viewport height */
}

.pagination-box {
  display: flex;
  align-items: center;
  margin: 15px 0px;
}

.button {
  border: 2px solid gray; /* Default border color */
  cursor: pointer;
  width: 80px;
  height: 40px;
  color: #fff; /* Default text color */
  font-weight: bold;
  background-color: transparent; /* Transparent background */
}

.button.active {
  border-color: #fff; /* Completed buttons have white border */
  color: #fff;
}
.button.inactive {
  border-color: gray; /* Inactive buttons have lightgray border */
  color: #fff; /* Inactive buttons have lightgray text color */
}

.button.completed {
  border-color: #fff; /* Completed buttons have white border */
  color: #fff; /* Completed buttons have white text color */
}

.divider {
  height: 2px;
  width: 40px;
  background-color: gray; /* Default color for dividers */
  margin-bottom: 15px;
}

.divider.white {
  background-color: #fff; /* White color for completed or active dividers */
}

.bottom-box {
  flex-grow: 1; /* Take up remaining vertical space */
  overflow-y: auto; /* Add scrollbars when content exceeds the maximum height */
  height: 100%;
  width: 100%;
}

.step-label {
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 10px;
  margin-top: 5px;
}

.step-label.active {
  color: #fff; /* Color for active step label */
}

/* Cart Information Box */

.cart-container {
  display: flex;
  height: 100%;
}

.cart-items-container,
.order-summary-container {
  flex: 1;
}

.cart-item-box {
  background-color: #171723; /* Change color as desired */
  height: 100%;
}

.order-confirm-box {
  background-color: #171723; /* Change color as desired */
  height: 100%;
}

/* Media query for wider screens */
@media (min-width: 769px) {
  .cart-items-container {
    flex: 3; /* Adjust the width as needed */
    margin-right: 3px;
  }

  .order-summary-container {
    flex: 2; /* Adjust the width as needed */
  }
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .order-confirm-box {
    min-height: 500px;
    overflow-y: auto;
  }
  .cart-container {
    flex-direction: column;
  }

  .cart-items-container,
  .order-summary-container {
    flex: 1; /* Equal width for both boxes */
    margin-top: 3px;
  }
}


/* Cart Payment Box */

.cart-container-P {
  display: flex;
  height: 100%;
}

.cart-items-container-P,
.order-summary-container-P {
  flex: 1;
}

.cart-item-box-P {
  background-color: #171723; /* Change color as desired */
  height: 100%;
}

.order-confirm-box-P {
  background-color: #171723; /* Change color as desired */
  height: 100%;
}

/* Media query for wider screens */
@media (min-width: 769px) {
  .cart-items-container-P {
    flex: 3; /* Adjust the width as needed */
    margin-right: 3px;
  }

  .order-summary-container-P {
    flex: 2; /* Adjust the width as needed */
  }
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .order-confirm-box-P {
    min-height: 500px;
    overflow-y: auto;

  }
  .cart-container-P {
    flex-direction: column;
    
  }

  .cart-items-container-P,
  .order-summary-container-P {
    flex: 1; /* Equal width for both boxes */
    margin-top: 3px;
  }
}

