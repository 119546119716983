.tab-system-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  background-color: #111118;
  border-bottom: 3px solid #171723;
}

.tab-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 200px;
  height: 100%;
  margin-right: 3px;
  background-color: #171723;
  cursor: pointer;
  position: relative;
}
.tab-cell:hover {
  /* background-color: #2e2e45; */
}

.tab-cell.selected {
  background-color: #2e2e45;
}

.tab-system-name {
  color: #fff;
  font-weight: 400;
  font-family: "Kanit", sans-serif;
  font-size: 12px;
  user-select: none; /* Prevent selection of tab text */
  cursor: text;
}

.add-new-tab-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 50px;
  height: 50%;
  margin-right: 3px;
  margin-top: 11px;
  cursor: pointer;
}

.add-new-tab-button-wrapper img {
  width: 70%;
  height: 70%;
  object-fit: contain; /* Ensure the image fits inside its container */
  user-select: none; /* Prevent selection of tab text */
  pointer-events: none; /* Disable user interaction */
}

.cross-tab {
  display: none; /* Initially hide the cross */
  justify-content: center;
  align-items: center;
  flex: 0 0 20px;
  height: 50%;
  margin-right: 6px;
  cursor: pointer;
  position: absolute; /* Position the cross absolutely */
  right: 0; /* Align to the far-right */
  z-index: 4; /* Ensure the cross is above other elements */
}

.tab-cell:hover .cross-tab {
  display: flex; /* Show the cross on hover */
}

.cross-tab img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none; /* Prevent selection of tab text */
  pointer-events: none; /* Disable user interaction */
}

.change-tab-name-input {
  background-color: transparent;
  border: none; /* Remove border */
  outline: none; /* Remove outline */
  color: white; /* Text color */
  font-size: 12px; /* Font size */
  font-weight: 400; /* Font weight */
  text-align: center; /* Center text horizontally */
  padding: 0; /* Remove default padding */
}
