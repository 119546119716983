.home-page-wrapper {
  height: 100vh;
  width: 100%;
  /* background-color: red; */
  overflow-y: auto;
}

.home-page-banner {
  height: 500px;
  width: 100%;
  background-color: #111117; /* Fallback color for browsers that don't support background images */
  margin-bottom: 10px;
  color: #fff;
  position: relative;
  overflow: hidden; /* Ensures child elements do not overflow */
}

.home-page-banner-text-box {
  display: flex;
  flex-direction: column;
  z-index: 2;
  justify-content: center;
  padding-left: 80px;
  color: #fff;
  position: relative;
  margin-top: 60px;
}

.home-page-banner-text-box-title {
  font-size: 36px;
  margin-bottom: 10px;
  width: 550px;
  font-weight: 600;
}

.home-page-banner-text-box-subtitle {
  font-size: 24px;
  margin-bottom: 20px;
  width: 500px;
  font-weight: 400;
}

.home-page-banner-text-box-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 80px;
}

.home-page-banner-text-box-left-button {
  padding: 14px 20px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  text-align: center;
  font-weight: 800;
  border: 1px solid transparent;
}

.home-page-banner-text-box-left-button:hover {
  background-color: #0056b3;
}

.home-page-banner-text-box-right-button:hover {
  border: 1px solid #fff;
}

.home-page-banner-text-box-right-button {
  padding: 14px 20px;
  font-size: 14px;
  color: #fff;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  text-align: center;
  font-weight: 800;
  border: 1px solid transparent;
}

.home-page-banner-back-box {
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}

.home-page-banner-back-box img {
  max-width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the container without distortion */
}

.supporters-banner-homepage {
  width: 100%;
  height: 120px;
  background: linear-gradient(to right, #1b1b2b, #111117);
  display: flex;
  flex-direction: row;
  justify-content: left; /* Distribute space around the images */
  align-items: center; /* Center images vertically */
  padding-left: 32px;
}

.banner-image {
  height: 40px; /* Adjust the height as needed */
  width: auto; /* Maintain aspect ratio */
  margin: 10px 40px;
}

/*
    Community Crafts Homepage
*/

.community-crafts-wrapper-homepage {
  width: 100%;
  margin-top: 3px;
  padding: 40px 70px;
  padding-bottom: 200px;
}

.community-crafts-title-homepage {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
}

.community-crafts-grid-homepage {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-gap: 20px; /* Space between cells */
}

/* Media query for smaller screens */
@media (max-width: 1400px) {
  /* Adjust the max-width as needed */
  .community-crafts-grid-homepage {
    grid-template-columns: 1fr; /* 1 column, each cell takes full width */
  }
}

.community-crafts-grid-item-homepage {
  background: linear-gradient(to right, #1b1b2b, #111117);
  color: #333;
  padding: 20px;
  text-align: center;
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 400px;
}
.community-crafts-grid-item-homepage.right-side {
  background: linear-gradient(to right, #111117, #1b1b2b);
}

/* CELL TOP BOX */
.community-crafts-grid-item-homepage-topbox {
  width: 100%;
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.community-crafts-grid-item-homepage-topbox-left {
  /* background-color: blue; */
  height: inherit;
  text-align: left;
}

.community-crafts-grid-item-homepage-topbox-left-title {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  /* margin-top: 10px; */
}

.community-crafts-grid-item-homepage-topbox-left-subtitle {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
}

.community-crafts-grid-item-homepage-topbox-right {
  height: inherit;
  display: flex;
  align-items: center; /* Vertically centers the items */
}

.community-crafts-grid-item-homepage-topbox-right .image {
  width: 50px; /* Set your desired width */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Space between image and text */
}

.community-crafts-grid-item-homepage-topbox-right .text {
  color: white; /* Text color */
  font-size: 16px; /* Font size */
  font-weight: 600;
}

/* CELL IMAGE BOX */
.community-crafts-grid-item-homepage-imagebox {
  height: 220px;
  border: 0.5px solid #ffffff77;
}

/* CELL BOTTOM BOX */
.community-crafts-grid-item-homepage-bottombox {
  height: 80px;
  width: 100%;
  /* background-color: red; */
  display: flex;
  flex-direction: row;
}

.community-crafts-grid-item-homepage-bottombox-left {
  width: 50%;
  /* background-color: blue; */
  height: inherit;
  display: flex;
  flex-direction: row;
  align-items: center; /* Center content vertically */
  justify-content: baseline; /* Center content horizontally */
}

.community-crafts-grid-item-homepage-bottombox-left-likebutton {
  align-items: center;
}

.community-crafts-grid-item-homepage-bottombox-left-likebutton img {
  width: 30px; /* Set your desired width */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Space between image and text */
  cursor: pointer;
}

.community-crafts-grid-item-homepage-bottombox-left-text {
  color: #fff;
  font-weight: 600;
  margin-top: 5px;
}

.community-crafts-grid-item-homepage-bottombox-right {
  width: 50%;
  /* background-color: green; */
  height: inherit;
  align-items: center;
  justify-content: flex-end; /* Center content horizontally */
  display: flex;
}

.community-crafts-grid-item-homepage-bottombox-right button {
  color: #fff;
  background-color: #007bff;
  font-size: 14px;
  font-weight: 600;
  padding: 14px 30px;
}

.community-crafts-grid-item-homepage-bottombox-right button:hover {
  background-color: #0056b3;
}
