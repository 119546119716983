.cart-item-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow-y: hidden;
}

.top-div {
  height: 60px;
  padding: 18px;
}

.middle-div {
  overflow-y: auto;
  flex: 1; /* Take up remaining vertical space */
}


/* .bottom-div { */
  /* position: absolute; */
  /* bottom: 0; */
  /* width: 100%; */
  /* height: 130px; */
  /* background-color: #171723; */
  /* box-shadow: 0px -10px 40px rgba(0, 0, 0, 0.2); */
  /* padding-top: 10px; */
/* } */

/* Add padding for large screen sizes so all items are visible in local scroll */
@media (min-width: 768px) {
  .middle-div {
    padding-bottom: 100px;
  }
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .bottom-div {
    height: 50px; /* Adjust height for smaller screens */
    margin-top: 4px;
    padding-top: 0px;
  }
  .middle-div {
    overflow-y: hidden; /* Disable scrolling */
    flex: 1;
  }
  .bottom-div {
    display: none;
  }
}

.total-price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-left: 8px;
  margin-right: 8px;
}

/* cart cells detail page */

.cart-cell-container {
  display: flex;
  height: 100px; /* Set larger height */
  align-items: center;
  justify-content: space-between;
  padding: 20px; /* Increase padding */
  background-color: #171723;
  /* cursor: pointer; */
}
.cart-cell-container:hover {
  /* background-color: #232335; */
}

.cart-cell-left {
  width: 120px; /* Increase width for larger image */
}

.cart-cell-mid {
  flex: 1;
  padding: 0 0px; /* Increase padding */
}

.cart-cell-right {
  width: 120px; /* Increase width for larger price section */
}

.cart-cell-image {
  width: 100%; /* Occupy entire width of container */
  height: 100%; /* Occupy entire height of container */
  object-fit: cover; /* Crop the image to cover the entire container */
}

.cart-cell-text {
  font-family: "Kanit";
  font-size: 13px; /* Increase font size for larger cells */
  font-weight: normal;
  margin: 0; /* Remove default margin */
  color: white;
}

.cart-cell-text:first-child {
  font-weight: bold;
}

.cart-cell-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cart-cell-price {
  font-family: "Kanit";
  font-size: 16px; /* Increase font size for larger cells */
  font-weight: 500;
  color: white;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .cart-cell-container {
    height: 120px; /* Set larger height */
  }

  .cart-cell-container:hover {
    background-color: #171723;
  }

  .cart-cell-left {
    width: 100px; /* Increase width for larger image */
  }

  .cart-cell-mid {
    flex: 1;
    padding: 0 20px; /* Increase padding */
  }

  .cart-cell-right {
    width: 120px; /* Increase width for larger price section */
  }

  .cart-cell-image {
    width: 100%; /* Occupy entire width of container */
    height: 100%; /* Occupy entire height of container */
    object-fit: cover; /* Crop the image to cover the entire container */
  }

  .cart-cell-text {
    font-family: "Kanit";
    font-size: 13px; /* Increase font size for larger cells */
    font-weight: normal;
    margin: 0; /* Remove default margin */
    color: white;
  }

  .cart-cell-text:first-child {
    font-weight: bold;
  }

  .cart-cell-stack {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cart-cell-price {
    font-family: "Kanit";
    font-size: 16px; /* Increase font size for larger cells */
    font-weight: 500;
    color: white;
  }
}
