.container-wrapper-wpn-field {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  margin-top: -10px;
}

.header-container-wpn-field {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically center items */
}

.left-text-wpn-field {
  font-size: 16px; /* Specify font size */
  font-weight: bold; /* Specify font weight */
  font-family: Arial, sans-serif; /* Specify font family */
}

.right-text-wpn-field {
  font-size: 16px; /* Specify font size */
  font-weight: bold; /* Specify font weight */
  color: #fff;
  margin-right: 10px;
}

.custom-input-dropdown-container-wpn-field {
  margin-top: 10px;

  position: relative;
  background-color: #fff;
  padding: 1px;
}

.dropdown-menu-wpn-field {
  max-height: 220px;
  overflow-y: scroll; /* Always show scrollbar */
  position: absolute;
  top: calc(100% + 5px);
  z-index: 10;
  background-color: #232335;
  width: 100%;
  padding: 10px 0px;
  margin-top: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 1); /* Box shadow properties */

}

.dropdown-menu-wpn-field::-webkit-scrollbar-thumb {
  background-color: #383852;
 
}


.actual-input-wpn-field {
  color: white;
  background-color: #232335;
  padding: 8px 10px;
  width: 100%;
  font-size: 10px;
}

.select-option-cell-wpn-field {
  font-size: 10px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  font-weight: 400;
  padding: 10px 10px;
}

.select-option-cell-wpn-field:hover {
  background-color: #2e2e45;
}
