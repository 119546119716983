/* Checkbox.css */

.checkbox-dropdown {
  margin-bottom: 10px;
}

.checkbox-divider {
  border: 1px 0 0 0;
  border-color: #171723;
  margin-bottom: 5px;
}

.checkbox-dropdown summary {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  list-style: none;
  user-select: none;
}

.checkbox-dropdown label {
  user-select: none;
  margin-left: 8px;
  font-size: 13px;
  font-weight: 400;
}

.checkbox-dropdown input[type="checkbox"] {
  padding-bottom: 0px;
}

.checkbox-dropdown input[type="checkbox"]:checked + label {
  font-weight: 600;
}

.dropdown-section {
  margin-top: 5px;
}

.checkbox-option {
  display: flex; /* Set display to flex */
  align-items: center; /* Center vertically */
  justify-content: space-between; /* Add space between elements */
  height: 35px;
  position: relative; /* Make the container relative for absolute positioning */
}

.checkbox-option:hover {
  background-color: #232335;
}

.only-button {
  font-size: 10px;
  visibility: hidden;
  font-weight: 800;
  margin-right: 20px;
}

.checkbox-option:hover .only-button {
  visibility: visible;
}

.option-label {
  font-size: 12px;
  margin-left: 5px;
  align-self: flex-start; /* Align the option label to the left */
  margin-left: 15px;
}

.checkbox-label-container {
  display: flex;
  align-items: center;
}
