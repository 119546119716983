.steam-trade-page-wrapper {
  width: 100%;
  height: 100%;
  background-color: #171723;
  display: flex;
  flex-direction: column;
}

.steam-trade-page-title-wrapper {
  width: 100%;
  height: 60px;
  margin-bottom: 15px;
}

.steam-trade-page-title-wrapper p {
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  margin-top: 20px;
  margin-left: 20px;
}

.steam-trade-page-bots-wrapper {
  width: 100%;
  background-color: #171723;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
}

.steam-trade-page-bot-cell {
  background-color: #232335;
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  margin-left: 40px;
  margin-bottom: 3px;
  margin-top: 0px;
  padding: 10px;
}

/* Remove side padding on small screens */
@media (max-width: 600px) {
  .steam-trade-page-bot-cell {
    margin-right: 0;
    margin-left: 0;
  }
}

.grid-container-bot-item-cells {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px; /* Adjust the gap between cells */
  padding: 10px; /* Padding inside the grid container */
  width: 100%; /* Ensure the container fits within the parent padding */
  box-sizing: border-box;
}

.steam-trade-bot-icon-cell-wrapper {
  /* background-color: #1e1e2e; */
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: relative; /* Ensure the image can be positioned absolutely */
  padding-top: 100%; /* This creates a square aspect ratio */
}

.steam-trade-cell-image-bot-icon {
  position: absolute;
  top: 17px;
  left: 15px;
  height: 70px;
  width: 70px;
  object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */
}

.steam-trade-info-cell-wrapper {
  /* background-color: #2a2a3d; */
  text-align: left;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  padding-left: 0;
  grid-column: span 2; /* This makes the cell span 2 columns, doubling its width */
}

.steam-trade-item-cell-wrapper {
  background-color: #1e1e2e;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  position: relative; /* Ensure the image can be positioned absolutely */
  padding-top: 100%; /* This creates a square aspect ratio */
}

.steam-trade-item-cell-empty-wrapper {
  background-color: #1e1e2e;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  position: relative; /* Ensure the image can be positioned absolutely */
  padding-top: 100%; /* This creates a square aspect ratio */
}

.steam-trade-cell-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */
  border-radius: 5px;
}

.accept-trade-button-cell-wrapper {
  text-align: center;
  color: #fff; /* White text */
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: span 3; /* This makes the cell span 2 columns, doubling its width */
}

.accept-trade-button {
  background-color: #1e90ff;
  color: #fff; /* White text */
  font-size: 13px; /* Increased font size for better visibility */
  font-weight: bold;
  cursor: pointer;
  margin: 10px; /* Add margin around the button */
  padding: 15px 30px; /* Add padding inside the button */
}

.steam-trade-bot-trade-icon-cell-wrapper {
  /* background-color: #1e1e2e; */
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: relative; /* Ensure the image can be positioned absolutely */
  padding-top: 100%; /* This creates a square aspect ratio */
}

.steam-trade-bot-trade-icon-cell-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */
  border-radius: 5px;
  padding: 30%;
}


.order-trades-steam-trade-status-completed-box {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #1d7435;
  padding: 8px 12px;
  margin-left: 20px;
}

.order-trades-steam-trade-status-offer-not-sent-box {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #f18e1d;
  padding: 8px 12px;
  margin-left: 20px;
  display: inline-block;
  white-space: nowrap; /* Prevents text wrapping */
}


.order-trades-steam-trade-status-pending-box {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #2d92ff;
  padding: 8px 12px;
  margin-left: 20px;
}
.order-trades-steam-trade-status-failed-box {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #ff2b2b;
  padding: 8px 12px;
  margin-left: 20px;
}
