/* MobileHomePage.css */

.mobile-home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Centers the container, but text will align left */
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #ffffff;
    /* Default text color set to white */
}

.logo-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.logo-image {
    max-width: 150px;
    height: auto;
}

.showcase-container {
    width: 100%;
    margin-bottom: 20px;
}

.showcase-image {
    width: 100%;
    border-radius: 8px;
}

/* Text alignment and color for title and subtitles */
.title,
.subtitle,
.subtitle-desktop-only {
    text-align: left;
    /* Aligns all text elements to the left */
    color: #ffffff;
    /* Ensures text color is white */
}

.title {
    font-size: 35px;
    font-weight: bold;
    margin: 10px 0;
    width: 500px;
}

.subtitle {
    font-size: 20px;
    font-weight: 300;
    margin-top: 0;
    width: 500px;

}

.subtitle-desktop-only {
    font-size: 16px;
    font-weight: 300;
    margin-top: 10px;
    width: 500px;
    color: #b8b8b8;
}