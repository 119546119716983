.payment-checkout-container {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.payment-checkout-box {
  text-align: center;
  width: 400px;

  background-color: #101019;
  padding: 50px;
}

.payment-checkout-title {
  font-size: 24px;
  margin-bottom: 0px;
  color: #fff;
  font-weight: bold;
  text-align: left;
  font-family: "Kanit";
}

.payment-checkout-current-account-balance {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 400;
  color: #fff;
  font-weight: bold;
  text-align: left;
  margin-top: 50px;
}

.payment-checkout-subTitle {
  font-size: 14px;
  margin-bottom: 20px;
  color: #fff;
  text-align: left;
}

.payment-checkout-horizontalDiv {
  display: flex;
  justify-content: space-between; /* Adjusted */
  align-items: center;
  margin-bottom: 0px;
}

.payment-checkout-infoText {
  color: #fff;
  font-weight: bold;
  text-align: left;
  margin-top: 20px;
}

.payment-checkout-totalText {
  color: #fff;
  font-weight: bold;
  text-align: left;
  margin-top: 20px;
}

.payment-checkout-divider-info {
  height: 10px;
  border-bottom: 0.5px solid #ffffff64;
}

.payment-checkout-divider-info2 {
  height: 30px;
  border-bottom: 0.5px solid #ffffff64;
}

.payment-checkout-button-proceed {
  width: 100%;
  padding: 10px;
  background-color: #2e8fff;
  color: #fff;
  font-weight: 800;
  font-size: 12px;
  margin-top: 50px;
}
