.custom-checkbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.custom-checkbox.checked::after {
  content: "✕"; /* Change checkmark to cross when checked */
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px; /* Adjust font size as needed */
  margin-top: 0px;
  margin-left: 5px;

}

.custom-checkbox::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-radius: 0; /* Remove border radius */
  margin-left: 5px;
}

.custom-checkbox.checked::before {
  background-color: #f7793b;
  border-color: #f7793b;
}

.custom-checkbox:checked:active::before {
  background-color: transparent; /* Change to red when checkbox is pressed */
}
