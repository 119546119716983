.cart-cell {
  display: flex;
  height: 120px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #171723;
}

.empty-cart-cell {
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #171723;
}

.cart-cell:hover {
  background-color: #232335;
}

.left-section {
  width: 60px;
}

.mid-section {
  flex: 1;
  padding: 0 5px;
  margin-left: 10px;
}

.right-section {
  width: 80px;
  height: 100%;
}

.sticker-image {
  width: 80px; /* Set the desired width */
  height: 80px; /* Set the desired height */
  object-fit: cover; /* Crop the image to cover the entire container */
  object-position: center; /* Position the center of the image */
}

.sticker-image-empty-cart {
  width: 100%; /* Set the desired width */
  height: 100%; /* Set the desired height */
  object-fit: cover; /* Crop the image to cover the entire container */
  object-position: center; /* Position the center of the image */
  opacity: 0.4;
}

.vertical-text {
  font-family: "Kanit";
  font-size: 12px; /* Set the font size for all texts */
  font-weight: normal; /* Set normal weight by default */
  margin: 0; /* Remove default margin */
}

.vertical-text:first-child {
  font-weight: bold; /* Make the top text heavier */
  font-size: 10px; /* Increase font size for the top text */
}

.vertical-text:last-child {
  font-size: 10px; /* Decrease font size for the bottom text */
  font-weight: 200;
}

.vertical-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cart-right-text {
  font-family: "Kanit";
  font-size: 12px;
  color: white;
  font-weight: 500;
  margin-top: 18px;
}

.quantity-selector-box {
  width: 80px;
  height: 25px;
  background-color: white;
  margin-top: 10px;
}

/* QuantitySelector.css */
.quantity-selector {
  display: flex;
  justify-content: space-between;
}

.remove-from-cart-button-popup {
  width: 20px;
  height: 20px;
  margin-top: 10px;
  align-items: right;
  cursor: pointer;
  margin-left: 40px;
}

.remove-from-cart-button-popup:hover {
  background: rgba(0, 0, 0, 0.2);
}

.remove-from-cart-button-popup img {
  width: 20px; /* Adjust the size as needed */
  height: 20px;
}

.quantity-button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.quantity-display {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
