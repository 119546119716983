.profile-page-wrapper {
  width: 100%;
  display: flex;
  margin-top: 3px; /* Add 3px margin on top */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.profile-page-sidebar {
  min-width: 320px; /* Set sidebar width */
  padding-right: 20px;
  margin-top: 20px;
}

.sidebar-button {
  display: flex;
  align-items: center;
  padding: 10px 20px; /* Padding */
  border: none;
  margin-bottom: 10px; /* Margin between buttons */
  width: 100%;
  text-align: left;
  color: #fff;
  font-weight: 800;
  font-family: "Kanit";
}

.sidebar-button:hover {
  background-color: #242335;
}

/* Add a media query for screens smaller than a certain width */
@media only screen and (max-width: 1000px) {
  .profile-page-sidebar {
    display: none; /* Hide the sidebar */
  }

  /* Adjust the width of the content box to take up the full width */
  .profile-page-content-box {
    width: 100%;
    padding: 20px;
    padding-top: 0;
  }
}

.button-image {
  width: 20px; /* Adjust image width */
  height: 20px; /* Adjust image height */
  margin-right: 10px; /* Margin between image and text */
}

.button-text {
  flex-grow: 1; /* Allow text to take remaining space */
}

.profile-page-content-box {
  flex-direction: column; /* Stack items vertically */
  height: 100vh; /* Set the height */
  overflow-y: auto; /* Activate vertical scroll */
  width: 100%;
}

/* PERSONAL DETAILS */

.personal-details-content-section {
  height: auto;
  margin-bottom: 30px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.personal-details-title {
  height: 30px;
  color: #ffffff;
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align text to the left */
}

.personal-details-title-text {
  margin-left: 10px; /* Add some space between the left edge and text */
}

.personal-details-content {
  background-color: #171723;
  margin-top: 20px;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.personal-details-content-header {
  width: 100%;
  height: 80px;
  display: flex;
}

.personal-details-avatar {
  width: 80px;
  height: 100%;
  margin-right: 30px;
}

.avatar-image {
  width: 80px;
  height: 80px;
  object-fit: cover; /* Ensure the image covers the entire box */
}

.personal-details-info {
  flex-grow: 1; /* Take up remaining space */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the end (right) */
}

.personal-details-text {
  margin-bottom: 10px; /* Add space between text and button */
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  font-family: "Kanit";
}

.personal-details-button {
  /* No need for additional styles */
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  font-family: "Kanit";
  background-color: #2e8fff;
  padding: 10px 50px;
}

.personal-details-fields-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
}

.personal-details-field {
  margin-bottom: 3px; /* Adjust as needed */
  width: 100%;
  justify-content: space-between; /* Distribute space between items */
  display: flex;
}

.personal-details-field-left {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically */
  justify-content: flex-start; /* Align items horizontally */
}

.field-title {
  margin: 0; /* Remove default margin */
  color: #fff;
  font-weight: 800;
  font-family: "Kanit";
  font-size: 15px;
}

.field-info {
  margin-left: 10px;
  color: #fff;
  font-weight: 400;
  font-size: 15px;
}

.personal-details-field-right {
  flex: 1;
  text-align: right;
  align-items: flex-end;
}

.edit-image {
  width: 20px;
  height: 20px;
  object-fit: cover; /* Ensure the image covers the entire box */
  margin-left: auto; /* Align the image to the right */
}

.field-divider {
  border-top: 1px solid #242335; /* Change color and thickness as needed */
  margin: 30px 0px; /* Adjust spacing as needed */
}

/* GENERAL INFORMATION */

.general-information-content-section {
  height: auto;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.general-information-title {
  height: 30px;
  color: #ffffff;
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align text to the left */
}

.general-information-title-text {
  margin-left: 10px; /* Add some space between the left edge and text */
}

.general-information-content {
  background-color: #171723;
  margin-top: 20px;
  width: 100%;
  padding: 30px;
}

.currency-selector-title {
  margin-left: 15px;
  color: #fff;
  font-weight: 800;
  font-size: 15px;
}

.currency-selector-popup {
  background-color: #232335;
  width: 200px;
  height: auto;
  margin-left: 135px;
  margin-top: -30px;
  position: relative;
  z-index: 10; /* Set a high z-index value to bring it above other elements */
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;

}

.currency-selector-cell {
  height: 40px;
  width: 100%;
  background-color: #232335;
  cursor: pointer;  
}

.currency-selector-cell:hover {
  background-color: #2e8fff;  
}

.currency-selector-cell-title {
  margin-left: 12px;
  margin-top: 7px;
  color: #fff;
  cursor: pointer;  

  font-weight: bold;

}

.currency-selector-info-section {
  height: 40px;
  width: 100%;
  background-color: #232335;  

}

.currency-selector-info-section-text {
  margin-left: 12px;
  margin-top: 10px;
  color: #fff;
  font-size: 10px;
}

.currency-selector-field-right {
  flex: 1;
  text-align: right;
  align-items: flex-end;
  cursor: pointer;

}

.currency-edit-text-apply-change {
  font-size: 0.9em;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}



.personal-details-input-field-edit {
  background-color: #242335;
  padding: 15px 20px;
  width: 600px;
  border: none; /* Remove border */
  color: white; /* Text color */
  font-size: inherit; /* Use the same font size as the parent */
  font-family: inherit; /* Use the same font family as the parent */
  margin: 0; /* Remove margin */
  outline: none; /* Remove focus outline */
  margin-left: 10px;
}

.edit-text-apply-change {
    align-items: center;
    padding-top: 17px;
    height: 60px;
    font-weight: 800;
}

/* All trades */

.all-trades-section {
  height: auto;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.all-trades-header {
  height: 30px;
  color: #ffffff;
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align text to the left */
}

.all-trades {
  background-color: #171723;
  margin-top: 20px;
  width: 100%;
  padding: 30px;
  margin-bottom: 50px;
}


/* Balance and Transactions */

.balance-and-transactions-section {
  height: auto;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.balance-and-transactions-header {
  height: 30px;
  color: #ffffff;
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align text to the left */
}

.balance-and-transactions {
  background-color: #171723;
  margin-top: 20px;
  width: 100%;
  padding: 30px;
  margin-bottom: 200px;
}
