.balance-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.balance-modal-content {
  background: #171723;
  color: #fff;
  border-radius: 0px;
  position: relative;
  width: 1000px;
  height: 600px;
  max-width: 90%;
  display: flex;
}

.balance-modal-close-button {
  position: absolute;
  top: 10px;
  right: 25px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: #fff;
}

.balance-modal-back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #fff;
  font-weight: 600;
}

.balance-modal-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 40px;
}
.checkout-divider-info {
  height: 10px;
  border-bottom: 0.5px solid #ffffff98;
  margin-bottom: 100px;
}

.infoText {
  font-size: 15px;
  font-weight: 600;
}

.totalText {
  font-size: 15px;
  font-weight: 200;
}

.balance-modal-subtitle {
  font-size: 14px;
}

.balance-modal-subtitle-select-payment {
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.balance-modal-subtitle-enter-amount {
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 50px;
}

.balance-modal-left {
  width: 60%;
  background: transparent;
  padding: 20px;
}

.balance-modal-right {
  width: 40%;
  background: #101019;
  padding: 20px;
  margin: 100px 30px;
  display: flex;
  flex-direction: column;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.grid-item {
  background: #232335;
  color: #fff;
  padding: 20px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.grid-item.selected {
  background-color: #32324b;
}

.grid-item.selected:hover {
  background-color: #32324b;
}

.grid-item img {
  width: 35px; /* Adjust size as needed */
  height: 35px; /* Adjust size as needed */
  margin-right: 20px;
}

.grid-item:hover {
  background-color: #32324b;
}

.grid-item-text {
  display: flex;
  flex-direction: column;
}

.grid-item-title {
  font-size: 16px;
  font-weight: bold;
}

.grid-item-subtitle {
  font-size: 12px;
}

.grid-item-title-right {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 5px;
}

.grid-item-subtitle-right {
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: 400;
}

.right-side-modal-payment-selection-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0px;
}

.right-side-modal-payment-selection-left-text {
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: 400;
}

.grid-item-subtitle-right-right-text {
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: 800;
}

.divider-right-side-modal {
  height: 0.5px;
  background-color: #ffffff3e;
  width: 100%;
  margin-top: 20px;
}

.modal-proceed-to-payment-button {
  background-color: #2e8fff;
  font-size: 10px;
  font-weight: 600;
  padding: 20px 10px;
  margin-top: 160px;
}

.modal-proceed-to-payment-button-stage-two {
  background-color: #2e8fff;
  font-size: 10px;
  font-weight: 600;
  padding: 20px 10px;
  margin-top: 120px;
}

.modal-proceed-to-payment-button-disabled {
  background-color: #cccccc;
  font-size: 10px;
  font-weight: 600;
  padding: 20px 10px;
  margin-top: 130px;
  cursor: not-allowed;
}

/* Add this to your CSS file */
.amount-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border-radius: 5px;
  background: #232335;
  outline: none; /* Ensure no outline is shown */
}

.preset-amounts-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.preset-amount {
  background: #232335;
  color: #fff;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  font-weight: 600;
}

.preset-amount:hover {
  background: #32324b;
}
