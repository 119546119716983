.data-table-outer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    width: 100%;
    margin-top: 20px;
    overflow-x: scroll;
  }
  
  .data-table-title {
    font-size: 20px;
    font-weight: 600;
    color: white;
    margin-bottom: 12px; /* Space between header and content */
  }
  
  .data-table-wrapper {
    display: flex;
    flex-direction: column;
    align-items: left; /* Center horizontally */
    background-color: #171723;
    width: 100%;
    margin-top: 0px;
    overflow: auto;
    padding-bottom: 20px;
  }
  
  .search-bar-transactions {
    padding: 10px;
    width: 300px;
    height: 50px;
    background-color: #171723;
    border: 1px solid #3f3d4d;
    outline: none; /* Remove the default focus outline */
    transition: border-color 0.3s ease; /* Smooth transition for border color */
    color: #fff;
    margin-left: 55px;
  }
  
  .search-bar:focus {
    border-color: #ffffff; /* Change border color on focus */
  }
  
  .data-table {
    width: 100%;
    border-collapse: separate; /* Ensure separate borders */
    border-spacing: 0; /* Remove any spacing between cells */
    padding: 20px 50px;
    margin-top: 0px;
  }
  
  .data-table th,
  .data-table td {
    padding: 12px;
    border: 1px solid #3f3d4d; /* Add border to cells */
    color: #ffffff;
    text-align: left;
    min-width: 80px;
  }
  
  .data-table th {
    background-color: #171723;
    text-align: left;
    border-top: none; /* Remove top border */
  }
  
  .data-table tbody tr:nth-child(even) {
    background-color: #171723;
  }
  
  .data-table tbody tr:hover {
    background-color: #232335;
    cursor: pointer;
  }
  
  .data-table tbody td:first-child,
  .data-table thead th:first-child {
    border-left: none; /* Remove left border for first column */
  }
  
  .data-table tbody td:last-child,
  .data-table thead th:last-child {
    border-right: none; /* Remove right border for last column */
  }
  
  .data-table tbody tr:last-child td {
    border-bottom: none; /* Remove bottom border for last row */
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Adjust margin as needed */
  }
  
  .pagination button {
    padding: 10px 20px; /* Increase button padding for larger size */
    font-family: "Kanit", sans-serif; /* Use Kanit font */
    font-size: 16px; /* Adjust font size */
    background-color: #171723; /* Adjust button background color */
    color: #fff; /* Adjust button text color */
    border: 1px solid transparent;
    cursor: pointer;
    outline: none;
    margin: 0 5px; /* Adjust margin between buttons */
    border-radius: 0px;
    transition: background-color 0.3s ease;
  }
  
  .pagination button:hover {
    border: 1px solid #3f3d4d;
  }
  
  .pagination button:disabled {
    background-color: transparent; /* Disabled button color */
    border: 1px solid transparent;
    color: transparent;
    cursor: default;
  }
  
  .pagination .current {
    background-color: #232335; /* Current page button color */
  }
  