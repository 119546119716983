.container-wrapper {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  margin-top: 10px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically center items */
}

.left-text {
  font-size: 16px; /* Specify font size */
  font-weight: bold; /* Specify font weight */
  font-family: Arial, sans-serif; /* Specify font family */
}

.right-text {
  font-size: 16px; /* Specify font size */
  font-weight: bold; /* Specify font weight */
  color: #fff;
  margin-right: 10px;
}

.custom-input-dropdown-container {
  margin-top: 10px;

  position: relative;
  background-color: #fff;
  padding: 1px;
}

.dropdown-menu {
  height: 150px;
  overflow-y: scroll;
  position: absolute; /* Position absolutely to parent container */
  top: calc(100% + 5px); /* Position the dropdown below the input field */
  z-index: 10; /* Set a higher z-index value */
  background-color: #171723;
  width: 100%;
  padding: 10px 0px;
  background-color: #232335;
  margin-top: 5px;
}

.actual-input {
  color: white;
  background-color: #232335;
  padding: 8px 10px;
  width: 100%;
  font-size: 10px;
}

.select-option-cell {
  font-size: 10px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  font-weight: 400;
  padding: 10px 10px;
}

.select-option-cell:hover {
    background-color: #2E2E45;
}
