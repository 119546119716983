@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



/* Global Styles */
* {
  font-family: 'Kanit', sans-serif; /* Apply Kanit font to all elements */
  margin: 0;  /* Reset margin */
  padding: 0; /* Reset padding */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}
body {
    -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Kanit', sans-serif; /* You can specify the font for headers too */
}

/* Additional styles */
p {
  font-family: 'Kanit', sans-serif; /* Applying the font to paragraph elements */
}
