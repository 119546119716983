/* Style for the button */
.add-to-cart-button {
  border: none;
  color: white;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 0px;
  position: relative;
  z-index: 9; /* Ensure the button appears above other elements */
  margin-right: 10px;
  width: 25px;
  margin-bottom: 10px;
  display: none;
}

/* Hover effect */
.result-cell:hover .add-to-cart-button {
  display: inline-block; /* Show the button when hovering over parent cell */
}

.add-to-cart-button:hover {
  background-color: #2e8fff; /* Darker green */
}

.add-to-canvas-button {
  border: none;
  color: white;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  font-size: 10px;
  border-radius: 0px;
  position: relative;
  z-index: 9; /* Ensure the button appears above other elements */
  width: 45px;
  margin-bottom: 10px;
  display: none;
  font-weight: 800;
}

/* Hover effect */
.result-cell:hover .add-to-canvas-button {
  display: inline-block; /* Show the button when hovering over parent cell */
}

.add-to-canvas-button:hover {
  background-color: #2e8fff; /* Darker green */
}


/* Rest of your CSS */
.result-cell {
  background-color: #171723;
}

.result-cell:hover {
  background-color: #232335;
  cursor: pointer; /* Add cursor pointer */
}

.results-wrapper {
  position: relative;
  overflow-y: scroll;
  max-height: 100%;
}

.sticker-results-bottom-cell {
  background-color: transparent;
}

.bottom-loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: auto;
}

.discountbox {
  width: 60px;
  height: 20px;
  background: linear-gradient(
    to top right,
    #ff702b,
    #2d92ff
  ); /* Red to Orange */
  margin-top: 3px;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
  font-weight: 800;
}