.market-sidebar-container {
  background-color: #171723;
  width: 220px;
  min-width: 200px;
  height: 100vh;
  margin-right: 3px;
  color: white;
  overflow-y: auto;
  font-family: sans-serif;
  padding-left: 15px;
  font-weight: bold;
  padding-bottom: 160px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 3px;
}

.market-sidebar-header {
  font-family: "Kanit", sans-serif;
  color: white;
  margin-bottom: 15px;
  margin-top: 5px;
}

