.loading-cell {
  position: relative;
  background-color: #171723;
  height: 220px;
  padding: 10px; /* Add padding to accommodate labels */
}

.loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #20202e; /* Adjust as needed */
  animation: progress 2.5s infinite;
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
