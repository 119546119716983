/* BottomBar.css */
.bottom-bar {
    display: flex;
    justify-content: center; /* Centers the items horizontally */
    align-items: center; /* Aligns items vertically */
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-22.5%); /* Centers the container */
    gap: 5px; /* Adds space between the divs */
  }
  