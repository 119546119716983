/* BottomBarButton.css */
.bottom-bar-button {
    display: flex;
    justify-content: center;  /* Center the icon horizontally */
    align-items: center;      /* Center the icon vertically */
    background-color: #171723;
    border: none;
    padding: 10px;
    width: 60px;
    height: 60px;
    border-radius: 0px;
    cursor: pointer;
    color: white;
    box-sizing: border-box;   /* Ensure the border is inside the button */
    
    /* Prevent text selection */
    user-select: none;
}

/* Prevent image dragging and interaction */
.bottom-bar-button img {
    object-fit: contain; /* Ensure the image scales properly */
    width: 100%;         /* Ensure the image fills the available space */
    height: 100%;        /* Ensure the image fills the available space */
    
    /* Prevent dragging images */
    -webkit-user-drag: none; /* Prevent image drag in WebKit browsers */
    user-drag: none;         /* Prevent image drag in other browsers */

    /* Prevent interaction (no clicking, no dragging) */
    pointer-events: none;    /* Disable interaction with the image itself */
}

.bottom-bar-button:hover {
    background-color: #2e2e45;
}

.bottom-bar-button.large:hover {
    background-color: #c25f2d;
}

.bottom-bar-button.large {
    padding: 20px;
    width: 140px; /* Adjust width for large button */
    background-color: #F7793B;
    display: flex; /* Ensure flexbox is used for alignment */
}

.bottom-bar-button.large .icon {
    font-size: 20px;  /* Adjust font size for large button */
}

.bottom-bar-button.large .text {
    margin-right: 5px; /* Reduced space between text and icon */
    font-size: 14px;  /* Adjust font size for large button */
    font-weight: 600;
}

.bottom-bar-button.small {
    display: flex; /* Ensure flexbox is used for small button as well */
    justify-content: center; /* Center the icon horizontally */
    align-items: center;     /* Center the icon vertically */
}

.bottom-bar-button.small .icon {
    font-size: 20px; /* Adjust font size for small button */
}

.bottom-bar-button .text {
    margin-right: 10px;
}

/* Toggled button state */
.bottom-bar-button.toggled {
    background-color: #2e2e45;  /* Change to green or any color when toggled on */
}

.bottom-bar-button.toggled:hover {
    background-color: #2e2e45;  /* Darker green when hovered while toggled */
}
