/* Topbar Balance Section */
.top-bar-balance {
  /* box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5); */
  background-color: #232335;
}

.top-bar-balance:hover {
  cursor: pointer; /* Add cursor pointer */
  /* box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.8); */
}

.top-bar-cart {
  cursor: pointer; /* Add cursor pointer */
}

.topbar-link {
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer; /* Add cursor pointer */
  user-select: none;
}
.topbar-link:hover {
  background-color: #232335;
}

.topbar-wrapper {
  width: 100%;
  height: 55px;
  background-color: #171723;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px;
}

.left-section-topbar {
  display: flex;
  align-items: center;
  height: 100%;
}

.left-section-topbar-mobile {
  display: none;
  align-items: center;
  height: 100%;
}

.right-section-topbar {
  display: flex;
  align-items: center;
}

.middle-logo-section {
  display: none;
  height: 55px;
  user-select: none;
}

/* Media query for smaller screens */
@media (max-width: 1200px) {
  .left-section-topbar {
    display: none;
  }

  .left-section-topbar-mobile {
    display: flex;
    justify-content: flex-start; /* Position left on small screens */
    width: 33%; /* Occupy one-third of the available space */
  }

  .middle-logo-section {
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    width: 33%; /* Occupy one-third of the available space */
    margin-right: 300px;
  }

  .right-section-topbar {
    display: flex;
    justify-content: flex-end; /* Position right on small screens */
    align-items: center; /* Center the content vertically */
    width: 33%; /* Occupy one-third of the available space */
    margin-left: 20px;
  }

  .hamburger-menu-btn {
    width: 20px; /* Set the desired width */
    object-fit: cover; /* Crop the image to cover the entire container */
    object-position: center; /* Position the center of the image */
  }
}
