/* src/components/Modal/Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000; /* Make sure it's above the Three.js canvas */
  pointer-events: auto; /* Ensure it can receive clicks */
}

.modal-content {
  background: #171723;
  color: #fff;
  padding: 20px;
  border-radius: 0px;
  position: relative;
  width: 600px;
  max-height: 550px;
  max-width: 90%;
  padding-left: 30px;
  z-index: 2000; /* Ensure content is above modal overlay */
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 25px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

.choose-sticker-popup-title {
  font-size: 20px;
  font-weight: 600;
}

.choose-sticker-popup-subtitle {
  font-size: 14px;
}

.sticker-modal-cell {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 3px;
  margin-right: 3px;
  background-color: #232335;
}

.sticker-modal-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.sticker-modal-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.sticker-modal-title {
  font-size: 16px;
  font-weight: bold;
  font-weight: 600;
}

.sticker-modal-subtitle-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.sticker-modal-subtitle {
  font-size: 14px;
  color: #fff;
  margin-right: 10px;
  font-weight: 600;
}

.sticker-modal-discountbox {
  width: 60px;
  height: 20px;
  background: linear-gradient(
    to top right,
    #ff702b,
    #2d92ff
  ); /* Red to Orange */
  margin-top: 6px;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
  font-weight: 800;
}

.sticker-modal-add-to-cart-button {
  background-color: #2e8fff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 800;
  margin-right: 20px;
}
.sticker-modal-already-in-cart-button{
  background-color: #e70303;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: default;
  font-size: 12px;
  font-weight: 800;
  margin-right: 20px;
}
