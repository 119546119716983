.help-section {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: flex-start;
    align-items: flex-start; /* Align all content to the left */
    position: fixed;
    top: 180px;          /* Fixed distance from the bottom of the viewport */
    background-color: #171723;
    width: 310px;
    height: 100px;
    pointer-events: auto;  /* Enable interaction */
    transition: none;      /* Removes any transition effects for stable positioning */
    padding: 10px 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    color: white;          /* Text color */

    left: 50%;
    transform: translateX(-10%); /* Centers the container */
    
}

/* Prevent image dragging and interaction */
.help-section  img {

    
    /* Prevent dragging images */
    -webkit-user-drag: none; /* Prevent image drag in WebKit browsers */
    user-drag: none;         /* Prevent image drag in other browsers */

    /* Prevent interaction (no clicking, no dragging) */
    pointer-events: none;    /* Disable interaction with the image itself */
}

.help-header {
    display: flex;
    justify-content: space-between; /* Space between Keybindings and the quit button */
    align-items: center;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
}

.quit-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.quit-icon {
    width: 20px;
    height: 20px;
}

.help-content {
    display: flex;
    flex-direction: column; /* Stack rows vertically */
    align-items: flex-start; /* Align rows and content to the left */
    width: 100%;
}

.row {
    display: flex;
    justify-content: flex-start; /* Align text horizontally to the left */
    width: 100%;
    margin: 5px 0; /* Space between rows */
}

.row span {
    font-size: 10px;
    text-align: left; /* Ensure individual text aligns to the left */
    margin-right: 10px;
    font-weight: 600;
}
