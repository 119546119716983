.item-cell {
  transition: background-color 0.1s ease, box-shadow 0.3s ease;
  background-color: #171723;
  position: relative;
  height: 220px;
  padding-bottom: 10px;
  display: block;
  padding: 10px; /* Add padding to accommodate labels */
}

.item-cell:hover {
  background-color: #232335;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* ImageStickerBox */

.image-sticker-box {
  position: relative;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%;
  z-index: 1; /* Set z-index to 1 */
}

.item-image-box {
  width: 140px;
  height: 120px;
}

.stickers-box {
  position: absolute;
  top: 50%; /* Position the top edge of the box at the middle of the parent container */
  right: 0;
  transform: translateY(
    -50%
  ); /* Move the box up by 50% of its height to center it vertically */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; /* Center vertically */
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 0px;
  z-index: 2; /* Set z-index to 1 */
  width: 30px;
}

.individual-sticker-box {
  object-fit: contain;
  width: 50px;
}

/* LabelsBox */

.labels-box {
  bottom: 0; /* Position the bottom edge of the box at the bottom of the parent container */
  left: 0; /* Position the left edge of the box at the left of the parent container */
  position: absolute; /* Change position to absolute */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start (left) */
  justify-content: center; /* Center vertically */
  z-index: 2; /* Set z-index to 2 to appear above */
  padding-left: 20px;
  padding-bottom: 20px;
}

.top-label {
  display: flex; /* Use Flexbox */
  justify-content: flex-start; /* Align items to the start (left) */
  align-items: center; /* Center items vertically */
  color: #fff;
  text-align: left; /* Align text to the left */
  font-size: 12px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}

.bottom-label {
  color: #fff;
  text-align: left; /* Align text to the left */
  font-size: 14px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
}

/* On Cell Hover */

/* Hide label box */
.item-cell:hover .labels-box {
  visibility: hidden; /* Hide the components when hovering over the item-cell */
}

/* Make image/stickers smaller, move up right */
.item-cell:hover .image-sticker-box {
  height: 70%;
  z-index: 1; /* Set z-index to 1 */
}

.item-cell:hover .item-image-box {
  width: 110px;
  height: 70px;
  margin-bottom: 70px;
}

.item-cell:hover .stickers-box {
  top: 50%; /* Position the top edge of the box at the middle of the parent container */
  right: 0;
  transform: translateY(
    -47%
  ); /* Move the box up by 50% of its height to center it vertically */
  display: flex;

  padding-right: 0px;
  z-index: 2; /* Set z-index to 1 */
  width: 20px;
}

.item-cell:hover .individual-sticker-box {
  width: 40px;
}

/* New shit to show on hover : BIG alternatives of divs */

.labels-box-big {
  bottom: 0; /* Position the bottom edge of the box at the bottom of the parent container */
  left: 0; /* Position the left edge of the box at the left of the parent container */
  position: absolute; /* Change position to absolute */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start (left) */
  justify-content: center; /* Center vertically */
  z-index: 2; /* Set z-index to 2 to appear above */
  padding-left: 20px;
  padding-bottom: 75px;

  visibility: hidden;
}

.item-cell:hover .top-label-big {
  visibility: visible;
}

/* Big label box */
.top-label-big {
  display: flex; /* Use Flexbox */
  justify-content: flex-start; /* Align items to the start (left) */
  align-items: center; /* Center items vertically */
  color: #fff;
  text-align: left; /* Align text to the left */
  font-size: 12px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;

  visibility: hidden; /* Hide the components when hovering over the item-cell */
}

.item-cell:hover .top-label-big {
  visibility: visible; /* Hide the components when hovering over the item-cell */
}

.bottom-label-big {
  color: #fff;
  text-align: left; /* Align text to the left */
  font-size: 14px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;

  visibility: hidden; /* Hide the components when hovering over the item-cell */
}

.item-cell:hover .bottom-label-big {
  visibility: visible; /* Hide the components when hovering over the item-cell */
}

.floatbar-container {
  z-index: 4;
  width: 170px;
  height: 25px;
  visibility: hidden;
  margin-top: 5px;
  padding-left: 2px;
}
.item-cell:hover .floatbar-container {
  visibility: visible; /* Hide the components when hovering over the item-cell */
}

.item-cell {
  position: relative; /* Ensure relative positioning for the parent container */
}

.button-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  visibility: hidden; /* Hide the components when hovering over the item-cell */
  z-index: 3;
}

.item-cell:hover .button-wrapper {
  visibility: visible; /* Show the components when hovering over the item-cell */
}

.buttons-row {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex; /* Use flexbox */
  justify-content: space-between; /* Align buttons horizontally with space between them */
}

.item-cell:hover .buttons-row {
  visibility: visible; /* Show the components when hovering over the item-cell */
}

.market-button {
  height: 40px;
  background-color: #2E8FFF;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  flex-grow: 1; /* Take up remaining space */
  display: flex; /* Use flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  margin-right: 3px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}

.more-button {
  width: 40px;
  height: 40px;
  background-color: #2E8FFF;
  display: flex; /* Use flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.market-button:hover {
  background-color: #2E8FFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.more-button:hover {
  background-color: #2E8FFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.market-modal-popup {
  position: absolute;
  width: 250px;
  height: 300px;

  /* Above all else */
  z-index: 5;
  background-color: #232335; /* Initial background color moved to CSS */
  
  /* box shadow left */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);

  /* box shadow right */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  
}
