.this-craft-sidebar-container {
  background-color: #171723;
  width: 240px;
  min-width: 240px;
  height: 100vh;
  margin-right: 3px;
  color: white;
  overflow-y: scroll;
  font-family: sans-serif;
  font-weight: bold;
  padding-bottom: 20px;
  margin-top: 3px;

}

.name-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the start of the flex container */
  align-items: left;
  padding: 10px;
  padding-bottom: 7px;
}

.name-box p {
  text-align: left; /* Align text to the right */
  font-size: 10px;
  color: #fff;
  margin-bottom: 5px;
}

.name-box input {
  width: 100%;
  padding: 8px;
  /* border: 1px solid #ccc; */
  height: 34px;
  font-size: 12px;
  background-color: #232335;
  user-select: all;
}

.name-box button {
  margin-top: 5px;
  background-color: #f7793c; /* Change to your desired button color */
  color: #ffffff;
  cursor: pointer;
  border-radius: 0;
  width: 100%;
  padding: 12px;
  font-size: 9px;
  font-weight: 800;
}

.social-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the start of the flex container */
  align-items: left;
  padding: 10px;
  margin-bottom: 5px;
}

.social-box p {
  text-align: left; /* Align text to the right */
  font-size: 10px;
  color: #fff;
  margin-bottom: 5px;
  user-select: none;
}

.copy-div p {
  font-size: 8px;
}

.social-box img {
  width: 20px;
  cursor: pointer;
}

.divider-box {
  display: flex;
  flex-direction: column;
  height: 3px;
  background-color: #111118;
}

.skin-selector-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the start of the flex container */
  align-items: left;
  padding: 10px;
  user-select: none;
}

.skin-selector-box p {
  text-align: left; /* Align text to the right */
  font-size: 10px;
  color: #fff;
  margin-bottom: 5px;
}

.stickers-on-canvas-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the start of the flex container */
  align-items: left;
  padding: 10px;
  padding-bottom: 7px;
  user-select: none;
}

.stickers-on-canvas-box p {
  text-align: left; /* Align text to the right */
  font-size: 10px;
  color: #fff;
  margin-bottom: 5px;
}

.stickers-on-canvas-box button {
  margin-top: 5px;
  background-color: #2e8fff; /* Change to your desired button color */
  color: #ffffff;
  cursor: pointer;
  border-radius: 0;
  width: 100%;
  padding: 12px;
  font-size: 9px;
  font-weight: 800;
}

.selected-sticker-box {
  width: 100%;
  background-color: #232335;
  padding: 10px;
  border: 1px solid #bbbbbb;
  cursor: pointer;
  user-select: none;
}

.top-box-selected-sticker {
  height: 80px; /* Adjust the height as needed */
  width: 100%;
  display: flex; /* Use flexbox */
  justify-content: space-between; /* Horizontally space elements */
  padding-top: 20px;
}

.image-box-selected-sticker {
  /* Define styles for the image box */
  height: 100%;
  width: 35%;
}

.info-box-selected-sticker {
  /* Define styles for the info box */
  height: 100%;
  width: 65%;
}

.info-box-selected-sticker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.selected-sticker-name {
  text-align: left;
  font-size: 10px;
  margin-left: 6px;
  margin-right: 10px;
  margin-top: 5px;
}
.selected-sticker-price {
  text-align: left;
  font-size: 10px;
  margin-left: 6px;
  margin-right: 10px;
  margin-top: 5px;
}

.selected-sticker-add-to-cart {
  margin: 10px auto;
  display: block;
  font-size: 8px;
  padding: 10px 30px;
  font-weight: 800;
  margin-left: 7px;
  background-color: #2e8fff;
}

.selected-sticker-out-of-stock-banner {
  margin: 10px auto;
  display: block;
  font-size: 8px;
  background-color: #bbbbbb;
  margin-top: 10px;
  padding: 10px 30px;
  font-weight: 800;
}

.bottom-box-selected-sticker {
  display: flex;
  justify-content: center;
  font-family: Arial, sans-serif; /* Example font family */
  height: 70px; /* Adjust the height as needed */
  width: 100%;
  padding: 0 20px; /* Adjust padding for left and right margin */
  background-color: #232335;
  margin-top: 20px;
}

.coordinate-box-selected-sticker {
  display: flex;
  justify-content: space-between;
  max-width: 600px; /* Adjust this value according to your design */
  width: 100%; /* Ensure the box takes up the full width */
}

.coordinate-left-side,
.coordinate-right-side {
  display: flex;
  flex-direction: column;
}

.coordinate-left-side-top,
.coordinate-left-side-bottom,
.coordinate-right-side-top,
.coordinate-right-side-bottom {
  display: flex;
  justify-content: space-between; /* Align values to the right */
  align-items: center;
}

.coordinate-left-side p,
.coordinate-right-side p {
  margin: 0;
  font-size: 12px; /* Example font size */
  font-weight: normal; /* Example font weight */
  font-family: Arial, sans-serif; /* Example font family */
  padding: 7px;
}

.coordinate-left-side-top,
.coordinate-right-side-top {
  margin-bottom: 0px;
}

.coordinate-left-side-bottom,
.coordinate-right-side-bottom {
  margin-top: 0px;
}

.remove-canvas-wrapper {
  width: 100%;
  background-color: #232335;
  padding-top: 10px;
  padding-bottom: 10px;
}

.selected-sticker-remove-from-canvas {
  display: block;
  font-size: 8px;
  padding: 8px 25px;
  font-weight: 800;
  margin: 0px auto;
}

.selected-sticker-remove-from-canvas:hover {
  background-color: #be2323;
}

.unselected-sticker-box {
  height: 80px; /* Adjust the height as needed */
  width: 100%;
  display: flex; /* Use flexbox */
  justify-content: space-between; /* Horizontally space elements */
  user-select: none;
}

.unselected-sticker-box:hover {
  background-color: #232335;
}

.unselected-sticker-box-left {
  height: 100%;
  width: 35%;
}

.unselected-sticker-box-right {
  height: 100%;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  cursor: pointer;
}

.unselected-sticker-name,
.unselected-sticker-price {
  text-align: left;
  font-size: 10px;
  margin-left: 6px;
  margin-right: 10px;
  align-self: left; /* Center horizontally */
}

.selected-stickers-add-all-to-cart {
  margin: 10px auto;
  display: block;
  font-size: 8px;
  padding: 8px 25px;
  font-weight: 800;
  background-color: #2e8fff;
}
.selected-stickers-clear-canvas {
  margin: 10px auto;
  display: block;
  font-size: 8px;
  padding: 8px 30px;
  font-weight: 800;
  margin-bottom: 22px;
  user-select: none;
}
.selected-stickers-clear-canvas:hover {
  background-color: #be2323;
}

.discountbox-thiscraft {
  width: 65px;
  height: 20px;
  background: linear-gradient(
    to top right,
    #ff702b,
    #2d92ff
  ); /* Red to Orange */
  margin-top: 3px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
  font-weight: 800;
  margin-left: 7px;
  padding: 10px 0;
}

.open-in-game-wrapper {
  height: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  margin-bottom: 150px;
}
.open-in-game-box {
  display: flex;
  padding: 10px;
  height: 40px;
  width: 100%;
  margin-top: 25px;
  background-color: #f7793b;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.open-in-game-box p {
  font-weight: 600;
  font-size: 12px;
}


.drop-bar {
  height: 3px;
  margin-left: 10px;
  margin-right: 10px;
}
