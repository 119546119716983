.container {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.box {
  text-align: center;
  max-width: 400px;

  background-color: #101019;
  padding: 50px;
}

.title {
  font-size: 24px;
  margin-bottom: 0px;
  color: #fff;
  font-weight: bold;
  text-align: left;
  font-family: "Kanit";
}

.subTitle {
  font-size: 14px;
  margin-bottom: 20px;
  color: #fff;
  text-align: left;
}

.horizontalDiv {
  display: flex;
  justify-content: space-between; /* Adjusted */
  align-items: center;
  margin-bottom: 0px;
}

.infoText {
  color: #fff;
  font-weight: bold;
  text-align: left;
}

.totalText {
  color: #fff;
  font-weight: bold;
  text-align: left;
}

.redBox {
  margin-top: 10px;
  margin-right: 5px;
}

.additionalText {
  color: #fff;
  font-size: 9px;
  text-align: left;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 40px;
}

.button-proceed {
  width: 100%;
  padding: 10px;
  background-color: #2e8fff;
  color: #fff;
  font-weight: 800;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.footerText {
  font-size: 9px;
  color: #fff;
  text-align: left;
}

/* Checkbox */

.custom-checkbox {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.custom-checkbox.checked::after {
  content: "✖"; /* Change checkmark to cross when checked */
  font-weight: bold;
  position: absolute;
  top: 12px;
  left: 50%;
  font-size: 19px; /* Adjust font size as needed */
  color: #fff;
}

.custom-checkbox::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-radius: 0; /* Remove border radius */
  margin-left: 5px;
}

.custom-checkbox.checked::before {
  background-color: #2e8fff;
  border-color: #2e8fff;
}

.custom-checkbox:checked:active::before {
  background-color: transparent; /* Change to red when checkbox is pressed */
}

.button-proceed.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
